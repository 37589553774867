import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { MainSection } from 'src/components/common/mainSection/MainSection'

export const Main = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        contentfulAbout {
          mainParagraph
          mainHeader
        }
        allContentfulAsset(filter: { title: { in: ["web-about-us-hero", "mobile-about-hero"] } }) {
          nodes {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const { mainHeader, mainParagraph } = data.contentfulAbout
      const images = data.allContentfulAsset.nodes

      return <MainSection images={images} heading={mainHeader} paragraph={mainParagraph} page='about' />
    }}
  />
)
