import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Wrapper } from 'src/components/common/wrapper/Wrapper'

import { Process } from './process/Process'

import * as S from './styles'

export const CreateProcess = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulCreateProcess {
          nodes {
            description
            title
          }
        }
        contentfulAbout {
          processHeader
          processParagraph
        }
      }
    `}
    render={data => {
      const processes = data.allContentfulCreateProcess.nodes
      const { processHeader, processParagraph } = data.contentfulAbout

      return (
        <S.Container>
          <Wrapper>
            <S.Heading as='h2' h2>
              {processHeader}
            </S.Heading>
            <S.Paragraph>{processParagraph}</S.Paragraph>
            <S.ArticlesWrapper>
              {processes.map(({ title, description }, i) => (
                <Process key={title} index={i} title={title} description={description} />
              ))}
            </S.ArticlesWrapper>
            <S.Paragraph>Masz pytania lub chcesz wiedzieć więcej?</S.Paragraph>
            <S.Button to='/kontakt'>Skontaktuj się &gt;</S.Button>
          </Wrapper>
        </S.Container>
      )
    }}
  />
)
