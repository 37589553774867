import React from 'react'

import * as S from './styles'

export const Ask = () => (
  <S.Container>
    <S.Wrapper>
      <S.Heading as='h2' h2>
        Chętnie odpowiemy na Twoje pytania. Razem możemy stworzyć coś wielkiego.
      </S.Heading>
      <S.Button to='/kontakt'>Skontaktuj się &gt;</S.Button>
    </S.Wrapper>
  </S.Container>
)
