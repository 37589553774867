import React from 'react'

import { MainTemplate } from 'src/components/templates/MainTemplate'
import { Main } from 'src/components/about/main/Main'
import { CreateProcess } from 'src/components/about/createProcess/CreateProcess'
// import { Slider } from 'src/components/about/sliderSection/SliderSection'
import { Ask } from 'src/components/about/ask/Ask'

import SEO from '../components/seo/seo'

const AboutPage = () => (
  <MainTemplate>
    <SEO description='dsafagaeggr' title='Producent maszyn przemysłowych i rolniczych | Kuliberda.tech' />
    <Main />
    <CreateProcess />
    {/* <Slider /> */}
    <Ask />
  </MainTemplate>
)

export default AboutPage
