import styled from 'styled-components'

import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'

import { device } from 'src/themes/breakpoints'

import settingsDark from 'src/images/settings-horizontal-dark.svg'

export const Article = styled.article`
  position: relative;
  margin-bottom: 5rem;
  background: url(${settingsDark}) no-repeat center;
  background-size: contain;
  overflow-x: hidden;

  @media ${device.mobileXl} {
    flex-basis: 25%;
    background: transparent;
  }
`

export const Number = styled.div`
  position: relative;
  text-align: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.bgBlue};
  font-size: ${({ theme }) => theme.fonts.size.xxxl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.primaryWhite};
  line-height: 65px;
  margin: 0 auto;

  span {
    position: relative;
  }

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 100vw;
    background: ${({ theme }) => theme.colors.bgBlue};
  }
`

export const Heading = styled(HeadingComponent)`
  text-align: center;
  margin: 2rem 0 4rem;

  @media ${device.mobileXl} {
    font-size: ${({ theme }) => theme.fonts.size.xs};
  }

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fonts.size.l};
  }
`

export const Paragraph = styled(ParagraphComponent)`
  text-align: center;

  @media ${device.mobileXl} {
    font-size: ${({ theme }) => theme.fonts.size.xxs};
    padding: 0 0.6rem;
  }

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fonts.size.s};
    padding: 0 1rem;
  }
`
