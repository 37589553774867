import styled from 'styled-components'

import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Paragraph as ParagraphComponent } from 'src/components/common/paragraph/Paragraph'
import { Button as ButtonComponent } from 'src/components/common/button/Button'

import { device } from 'src/themes/breakpoints'

import settingsDark from 'src/images/settings-horizontal-dark.svg'

export const Container = styled.section`
  padding: 5rem 0 0;

  @media ${device.mobileXl} {
    background: url(${settingsDark}) no-repeat center;
    margin-top: 5%;
  }

  @media ${device.tabletL} {
    margin-top: 12%;
  }

  @media ${device.laptop} {
    margin-top: 8%;
  }

  @media ${device.laptopM} {
    margin-top: 12%;
  }

  @media ${device.laptopL} {
    margin-top: 7%;
  }

  @media ${device.laptopXl} {
    margin-top: 0;
  }
`

export const ArticlesWrapper = styled.div`
  @media ${device.mobileXl} {
    display: flex;
    justify-content: space-between;
  }
`

export const Heading = styled(HeadingComponent)`
  text-align: center;
`

export const Paragraph = styled(ParagraphComponent)`
  text-align: center;
  margin: 1rem 0 4rem;
`

export const Button = styled(ButtonComponent)`
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.bgBlue};
`
