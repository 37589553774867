import React from 'react'
import PropTypes from 'prop-types'

import * as S from './style'

export const Process = ({ index, title, description }) => (
  <S.Article>
    <S.Number>
      <span>{index + 1}</span>
    </S.Number>
    <S.Heading as='h3' h3>
      {title}
    </S.Heading>
    <S.Paragraph>{description}</S.Paragraph>
  </S.Article>
)

Process.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}
