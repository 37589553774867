import styled from 'styled-components'

import { Wrapper as WrapperComponent } from 'src/components/common/wrapper/Wrapper'
import { Heading as HeadingComponent } from 'src/components/common/heading/Heading'
import { Button as ButtonComponent } from 'src/components/common/button/Button'

import { device } from 'src/themes/breakpoints'

export const Container = styled.section`
  padding: 5rem 0;

  @media ${device.mobileXl} {
    text-align: center;
  }
`

export const Wrapper = styled(WrapperComponent)`
  @media ${device.mobileXl} {
    text-align: center;
    max-width: 810px;
  }
`

export const Heading = styled(HeadingComponent)`
  margin-bottom: 5rem;
`

export const Button = styled(ButtonComponent)`
  margin: 0 auto;
`
